<script lang="ts" setup>
const props = defineProps<{
  chatbotId: string; // 7912181a-dc70-4097-b676-e4a3eb5c7c4b
  src: string; // http://backend-hw-chatbot.localhost/static/chatbot.js
  apiUrl: string; // http://backend-hw-chatbot.localhost/api
  textDe: string;
  textEn: string;
  elementId?: string;
  fullscreen?: boolean;
}>();

const emits = defineEmits(["initialized"]);

onMounted(async () => {
  try {
    const {
      init,
    }: {
      init: (
        chatbotId: string,
        apiUrl: string,
        textEn: string,
        textDE: string,
        fullscreen: boolean,
        elementId: string,
      ) => void;
    } = await import(props.src);

    init(
      props.chatbotId,
      props.apiUrl,
      props.textEn,
      props.textDe,
      props.fullscreen,
      props.elementId,
    );
    emits("initialized");
  } catch (e) {
    console.error(e);
  }
});

onBeforeUnmount(() => {
  const chatbots = document.getElementsByTagName("hw-chatbot");
  const toggleBtns = document.getElementsByTagName("hw-chatbot-toggle");

  [...chatbots].map((i) => i.remove());
  [...toggleBtns].map((i) => i.remove());
});
</script>

<template></template>
