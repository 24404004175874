<script setup lang="ts">

const { t } = useI18n();
const siteConfig = useSiteConfig();

useSeoMeta({
  description: t("meta.description"),
  ogDescription: t("meta.description"),
  ogImage: siteConfig.url + "/og-image.png",
  twitterCard: "summary_large_image",
  themeColor: "#1867C0",
});

</script>

<template>
  <!--  <Head>-->
  <!--    <VitePwaManifest />-->
  <!--  </Head>-->

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <Chatbot
      chatbot-id="628a3677-a5e4-4bdd-8631-ea7522676d51"
      src="https://chatbot.hw.ag/static/chatbot.js"
      api-url="https://chatbot.hw.ag/api"
      text-de="Hallo, ich bin dein KI-Assistent. Für Fragen zu den Inhalten der Website stehe ich zur Verfügung."
      text-en="Hello, I'm your AI assistant. I am available for questions about the contents of the website."
  />
</template>
